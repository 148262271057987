<template>
  <el-dialog title="SKU其他信息" :visible.sync="show" append-to-body :close-on-click-modal="false">
    <form-info-item label-width="auto" label="规格：">{{$goodsSpecConvert(sku && sku.specs)}}</form-info-item>
    <el-tabs v-model="currentTab" v-if="sku">
      <el-tab-pane label="SKU图片" name="image">
        <file-uploader v-model="sku.coverImg" :entity-id="sku.fileId" folder="goods/sku" :readonly="!editable">
          <div slot="tip" style="font-size: 12px; margin-bottom: 5px;" class="fc-g">不设置SKU图片将使用SPU中设置的图片展示</div>
        </file-uploader>
      </el-tab-pane>
      <el-tab-pane label="包装清单" name="bom">
        <datagrid-picker reference="选择商品" v-if="editable" reference-type="info" url="api/goods/sku" :exclude-keys="bomExcludeGoods" :params="{notId: sku.id}" :query-define="bom.goodsQueryDefine" :popper-width="800" :show-index="false" multiple submit @submit="handleBomAdd">
          <div class="padding-10 bc-l" slot="banner" slot-scope="scope">
            <el-input :maxlength="10" v-model="bom.goodsQueryDefine.name" @keypress.enter.native="scope.query" @clear="scope.query" :validate-event="false" clearable placeholder="输入商品名称进行搜索" style="width: 240px;">
              <el-button icon="el-icon-search" slot="append" @click.stop="scope.query" />
            </el-input>
          </div>
          <el-table-column prop="name" label="商品名称" min-width="160" />
          <el-table-column label="规格" min-width="120" show-overflow-tooltip>
            <template slot-scope="scope">{{$goodsSpecConvert(scope.row.specs)}}</template>
          </el-table-column>
          <el-table-column prop="brandName" label="品牌" width="120" />
          <el-table-column prop="categoryName" label="分类" width="150" show-overflow-tooltip />
        </datagrid-picker>
        <el-table :data="bom.items" empty-text="还没有包装清单" v-loading="bom.loading">
          <el-table-column prop="goodsName" label="商品名称" min-width="160" />
          <el-table-column prop="brandName" label="品牌" width="120" />
          <el-table-column label="规格" min-width="120" show-overflow-tooltip>
            <template slot-scope="scope">{{$goodsSpecConvert(scope.row.specs)}}</template>
          </el-table-column>
          <el-table-column label="数量" width="100" v-if="editable">
            <template slot-scope="scope">
              <el-input-number v-model="scope.row.num" :min="1" :max="9999" :step="1" :precision="0" size="mini" controls-position="right" style="width: 100%;" />
            </template>
          </el-table-column>
          <el-table-column prop="num" label="数量" width="100" v-else />
          <el-table-column width="50" v-if="editable" fixed="right">
            <div class="row-commands" slot-scope="scope">
              <el-button type="text" size="mini" @click="handleBomRemove(scope.$index)">删除</el-button>
            </div>
          </el-table-column>
        </el-table>
        <div style="margin-top: 20px;" v-if="editable">
          <el-button type="primary" icon="el-icon-check" :disabled="!bom.items || !bom.items.length" :loading="bom.saving" @click="handleBomSave" v-permission="['GOODS_ALL', 'GOODS_EDIT']">保存包装清单</el-button>
        </div>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>

<script>
import { saveBom, getBom } from "@/api/goods";

export default {
  props: {
    sku: Object,
    visible: Boolean,
    editable: Boolean,
  },
  data() {
    return {
      currentTab: "image",
      show: false,
      bom: {
        loading: false,
        goodsQueryDefine: {
          name: "",
        },
        items: [],
        saving: false,
      },
    };
  },
  computed: {
    bomExcludeGoods() {
      return (this.bom.items || []).map((o) => {
        return o.bomSkuId;
      });
    },
  },
  watch: {
    visible(val) {
      this.show = val;
    },
    show(val) {
      if (val !== this.visible) {
        this.$emit("update:visible", val);
      }
    },
    sku: "loadBoms",
  },
  methods: {
    loadBoms() {
      if (this.sku) {
        this.loading = true;
        getBom(this.sku.id)
          .then((res) => {
            this.bom.items = (res || []).map((item) => {
              return {
                bomSkuId: item.bomSkuId,
                goodsName: item.bomSku.name,
                brandName: item.bomSku.brandName,
                specs: item.bomSku.specs,
                num: item.num,
              };
            });
          })
          .finally((_) => {
            this.loading = false;
          });
      }
    },
    handleBomAdd(list) {
      if (list && list.length) {
        this.bom.items = (this.bom.items || []).concat(
          list.map((o) => {
            return {
              bomSkuId: o.id,
              goodsSpec: o.specs,
              goodsName: o.name,
              brandName: o.brandName,
              num: 1,
            };
          })
        );
      }
    },
    handleBomRemove(inx) {
      this.bom.items.splice(inx, 1);
    },
    handleBomSave() {
      if (this.sku && this.sku.id) {
        this.bom.saving = true;
        saveBom(this.sku.id, this.bom.items)
          .then((res) => {
            this.$notify({
              title: `保存包装清单成功`,
              type: "success",
              duration: 2500,
            });
          })
          .finally((_) => {
            this.bom.saving = false;
          });
      }
    },
  },
};
</script>