<template>
  <div class="app-commodity-manage-skus">
    <el-form ref="form" :model="form" label-width="74px" label-position="right" label-suffix=":" v-if="form" v-loading="loading">
      <div class="h s" style="margin-bottom: 20px;" v-if="editable">
        <div class="flex">
          <el-button-group>
            <!-- <el-button type="primary" @click="usingTemplate = true">使用模板快速创建规格</el-button> -->
            <el-button type="primary" @click="custom.show = true; custom.form = {name: '', value: ''};">添加新规格</el-button>
          </el-button-group>
          <div class="h gap-1x">
            <div class="no-flex bold" style="line-height: 30px;">规格明细&nbsp;&nbsp;</div>
            <div class="flex h wrap" v-if="specification && specification.length">
              <div class="_spec-group" v-for="s in specification" :key="s.name">
                <label>{{s.name}}</label>
                <template v-for="v in s.valueArray">
                  <!-- <el-tag :key="v.value" style="margin: 2px 5px 2px 0;" size="medium" type="info" :closable="!v.locked" @close="handleSpecValueRemove(s, v)">{{v.value}}</el-tag> -->
                  <el-tag :key="v.value" style="margin: 2px 5px 2px 0;" size="medium" :type="v.locked ? 'danger' : 'info'">
                    {{v.value}}
                    <a href="javascript:void(0)" class="el-icon-edit-outline" @click="handleSpecRename(s, v.value)" v-if="specEditable || !v.locked"></a>
                  </el-tag>
                </template>
                <!-- <el-popover :ref="`spec_adder_${s.index}`" placement="bottom" trigger="click">
                  <div>请输入新的{{s.name}}：</div>
                  <el-input v-model.trim="s._newValue" :maxlength="50" clearable style="margin: 10px 0;" />
                  <el-button type="primary" :disabled="!s._newValue" @click="handleSpecValueAdd(s)">确定</el-button>
                  <el-button size="mini" icon="el-icon-plus" slot="reference" />
                </el-popover>-->
              </div>
            </div>
            <div class="flex fc-g" v-else>还没有创建规格，可以通过模板快速创建，也可以点击“添加新规格”按钮手动创建或导入</div>
          </div>
        </div>
        <el-button type="primary" icon="el-icon-upload2" @click="handleImport" v-if="$erp.enable() && (!specification || specification.length <= 1)">导入</el-button>
      </div>

      <template v-if="editable">
        <el-table ref="list" :data="pagedSkus" row-key="_sid" empty-text="还没有可用的规格，您可以通过快速创建或手动添加规格。" :row-class-name="getSkuRowClass">
          <el-table-column width="35" fixed>
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row._checked" />
            </template>
          </el-table-column>
          <el-table-column v-for="c in columns" :column-key="c.prop" :key="c.prop" :prop="c.prop" :label="c.label" min-width="120" fixed>
            <template slot="header" slot-scope="scope">
              <div class="no-flex">{{scope.column.label}}&emsp;</div>
              <el-input v-model.trim="keywords[scope.column.property]" size="mini" class="flex" placeholder="查询" clearable @clear="doFilter" @keypress.enter.native="doFilter" />
            </template>
          </el-table-column>
          <!-- <el-table-column label="SKU条形码" width="150">
            <template slot-scope="scope">
              <el-input v-model="scope.row.barcode" :maxlength="40" size="mini" :disabled="!scope.row.enable" />
            </template>
          </el-table-column>-->
          <el-table-column label="SKU编码" width="210">
            <template slot="header" slot-scope="scope">
              <div class="no-flex">SKU编码&emsp;</div>
              <el-input v-model.trim="keywords.code" size="mini" class="flex" placeholder="查询" clearable @clear="doFilter" @keypress.enter.native="doFilter" />
            </template>
            <template slot-scope="scope">
              <el-input v-model="scope.row.code" :maxlength="40" size="mini" />
            </template>
          </el-table-column>
          <el-table-column width="210" v-if="$erp.enable()">
            <template slot="header" slot-scope="scope">
              <div class="no-flex">ERP编码&emsp;</div>
              <el-input v-model.trim="keywords.erpCode" size="mini" class="flex" placeholder="查询" clearable @clear="doFilter" @keypress.enter.native="doFilter" />
            </template>
            <template slot-scope="scope">
              <el-input v-model="scope.row.erpCode" :maxlength="40" size="mini" :readonly="scope.row._loading" @change="handleERPInfo(scope.row)" style="vertical-align:top">
                <el-button slot="append" icon="fas fa-mouse-pointer" size="mini" :loading="scope.row._loading" @click="handleErpCodePick(scope.row)"></el-button>
                 <!-- v-if="!scope.row.isImport" -->
              </el-input>
            </template>
          </el-table-column>
          <el-table-column label="常用规格" width="80" fixed="right">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.routine" size="mini" />
            </template>
          </el-table-column>
          <el-table-column label="上下架" width="80" fixed="right">
            <template slot="header" slot-scope="scope">
              <el-popover placement="bottom" trigger="hover" popper-class="no-padding">
                <div class>
                  <div class="el-select-dropdown__item" :class="{selected: keywords.onShelf == null}" @click="doOnShelfChange(null)">全部</div>
                  <div class="el-select-dropdown__item" :class="{selected: keywords.onShelf == true}" @click="doOnShelfChange(true)">已上架</div>
                  <div class="el-select-dropdown__item" :class="{selected: keywords.onShelf == false}" @click="doOnShelfChange(false)">已下架</div>
                </div>
                <div class="h c" slot="reference">
                  <span>{{keywords.onShelf == null ? "上下架" : keywords.onShelf == true ? "已上架" : "已下架"}}&nbsp;</span>
                  <i class="el-icon-arrow-down"></i>
                </div>
              </el-popover>
            </template>
            <template slot-scope="scope">
              <el-switch v-model="scope.row.onShelf" size="mini" v-permission="['GOODS_ALL', 'GOODS_ONSHELF']" />
            </template>
          </el-table-column>
          <el-table-column label="可使用返利" width="80" fixed="right">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.joinRebate" size="mini" />
            </template>
          </el-table-column>
          <!-- <el-table-column label="销售目标" width="80" fixed="right">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.isSaleTarget" size="mini" :disabled="!scope.row.enable" />
            </template>
          </el-table-column>-->
          <!-- <el-table-column label="最低零售价" width="140" fixed="right">
            <template slot-scope="scope">
              <price-input v-model="scope.row.lowestRetailPrice" size="mini" :min="0" :max="999999" :step-ratio="10000" :disabled="!scope.row.enable" />
            </template>
          </el-table-column>-->
          <el-table-column label="统一零售价" width="140" fixed="right">
            <template slot-scope="scope">
              <price-input v-model="scope.row.retailPrice" size="mini" :min="0" :max="999999" :step-ratio="10000" />
            </template>
          </el-table-column>
          <!-- <el-table-column label="起订数量" width="110" fixed="right">
            <template slot-scope="scope">
              <el-input-number v-model="scope.row.minSalesQuantity" :min="0" :max="999" controls-position="right" :step="1" :precision="0" size="mini" :disabled="!scope.row.enable" style="width: 100%;" />
            </template>
          </el-table-column>-->
          <el-table-column width="140" fixed="right">
            <div class="row-commands" slot-scope="scope">
              <el-button type="text" size="mini" @click="handleSkuDetail(scope.row)" v-if="scope.row.id">更多信息</el-button>
              <!-- <template v-if="scope.row.enableEdit"> -->
              <el-button type="text" size="mini" @click="handleSkuEdit(scope.row)" v-if="specEditable || scope.row.enableEdit">编辑</el-button>
              <el-button type="text" size="mini" class="danger" @click="handleSkuRemove(scope.row)" v-if="scope.row.enableEdit">移除</el-button>
              <!-- </template> -->
            </div>
          </el-table-column>
        </el-table>

        <el-pagination :total="skus.length" :current-page.sync="page" :page-size="size" hide-on-single-page v-if="skus && skus.length" style="padding: 10px 0; border-bottom: #EBEEF5 solid 1px;" />

        <div class="h c padding-10-0" style="border-bottom: #EBEEF5 solid 1px;" v-if="specification && specification.length">
          <el-button type="text" size="mini" @click="handleSelection(true)">全选</el-button>
          <el-divider direction="vertical" />
          <el-button type="text" size="mini" @click="handleSelection(false)">取消全选</el-button>
          <el-divider direction="vertical" />
          <el-button type="text" size="mini" @click="handleSelection()">反选</el-button>
          <!-- <el-divider direction="vertical" /> -->
          <!-- <el-tooltip content="自动选择含有【ERP编码】的规格，取消选择不含有【ERP编码】的规格。" placement="top">
            <el-button type="text" size="mini" @click="handleAutoSelection">自动勾选</el-button>
          </el-tooltip>-->
          <!-- <el-divider direction="vertical" />
          <el-button type="text" size="mini" @click="handleClearFilter">清除过滤</el-button>-->
          <div class="flex"></div>
          <el-form-item label="统一设置" label-width="84px" style="margin-bottom: 0 !important;">
            <div class="h c" style="margin: -6px 0;">
              <form-info-item label-position="top" style="width: 80px;" class="padding-0-10">
                <el-switch v-model="unify.routine" size="mini" />
              </form-info-item>
              <form-info-item label-position="top" style="width: 80px;" class="padding-0-10">
                <el-switch v-model="unify.onShelf" size="mini" v-permission="['GOODS_ALL', 'GOODS_ONSHELF']" />
              </form-info-item>
              <form-info-item label-position="top" style="width: 80px;" class="padding-0-10">
                <el-switch v-model="unify.joinRebate" size="mini" />
              </form-info-item>
              <!-- <form-info-item label-position="top" style="width: 140px;" class="padding-0-10">
                <price-input v-model="unify.lowestRetailPrice" size="mini" :min="0" :max="9999999" :step-ratio="10000" />
              </form-info-item>-->
              <form-info-item label-position="top" style="width: 140px;" class="padding-0-10">
                <!-- <price-input v-model="unify.retailPrice" size="mini" :min="0" :max="9999999" :step-ratio="10000" /> -->
              </form-info-item>
              <!-- <form-info-item label-position="top" style="width: 110px;" class="padding-0-10">
                <el-input-number v-model="unify.minSalesQuantity" :min="0" :max="999999" controls-position="right" :step="1" :precision="0" size="mini" style="width: 100%;" />
              </form-info-item>-->
              <form-info-item label-position="top" style="width: 140px;" class="padding-0-10">
                <el-dropdown size="mini" split-button type="text" trigger="click" @click="handleUnifyCommand(0)" @command="handleUnifyCommand" class="_sku_apply_btn" style="width: 100%;">
                  应用
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command="0">全部应用</el-dropdown-item>
                    <el-dropdown-item :command="true">只应用到已勾选</el-dropdown-item>
                    <el-dropdown-item :command="false">只应用到未勾选</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </form-info-item>
            </div>
          </el-form-item>
        </div>
        <div style="margin-top: 20px;" v-if="editable">
          <el-button type="primary" icon="el-icon-plus" :disabled="!specification || !specification.length" @click="handleSkuAdd">添加SKU</el-button>
          <el-button type="primary" icon="el-icon-check" :loading="saving" :disabled="!skus || !skus.length" @click="handleSubmit" v-permission="['GOODS_ALL', 'GOODS_EDIT']">保存SKU信息</el-button>
        </div>
      </template>
      <template v-else>
        <el-table ref="list" :data="skus" empty-text="该商品目前还没有SKU信息">
          <el-table-column v-for="c in columns" :key="c.prop" :prop="c.prop" :label="c.label" min-width="160" fixed />
          <el-table-column prop="barcode" label="SKU条形码" width="150" />
          <el-table-column prop="code" label="SKU编码" width="150" />
          <el-table-column prop="erpCode" label="ERP编码" width="150" />
          <el-table-column label="上下架" width="80">
            <template slot-scope="scope">
              <i class="fas fa-check fc-s" v-if="scope.row.onShelf"></i>
            </template>
          </el-table-column>
          <!-- <el-table-column label="参与返利" width="80">
            <template slot-scope="scope">
              <i class="fas fa-check fc-s" v-if="scope.row.isRebate"></i>
            </template>
          </el-table-column>-->
          <el-table-column label="销售目标" width="80">
            <template slot-scope="scope">
              <i class="fas fa-check fc-s" v-if="scope.row.isSaleTarget"></i>
            </template>
          </el-table-column>
          <!-- <el-table-column label="最低销售价" width="140" align="right" :formatter="r => { return '￥' + (r.lowestRetailPrice / 100).toFixed(2); }" /> -->
          <el-table-column label="统一零售价" width="140" align="right" :formatter="r => { return '￥' + (r.retailPrice / 100).toFixed(2); }" />
          <!-- <el-table-column prop="minSalesQuantity" label="起订数量" width="110" align="right" /> -->
          <el-table-column width="70">
            <div class="row-commands" slot-scope="scope">
              <el-button type="text" size="mini" @click="handleSkuDetail(scope.row)" v-if="scope.row.id">更多信息</el-button>
            </div>
          </el-table-column>
        </el-table>
      </template>

      <e-sku-detail :sku="detail.sku" :visible.sync="detail.show" :editable="editable" />
    </el-form>

    <el-dialog :visible.sync="usingTemplate" title="使用模板快速创建规格" append-to-body :close-on-click-modal="false" width="560px">
      <div class="fc-e">注意：当商品被销售后，不能再更改规格属性（即不可添加、删除、修改，也不能再通过规格模板创建），仅可以调整规格值；并且已销售的SKU所对应的规格值不能再被修改和删除。</div>
      <quick-select v-model="specTemplates" filterable url="api/specTemplate" placeholder="请选择规格模板" multiple @change="handleSpecTemplateChange" style="margin: 10px 0; width: 100%;" />
      <div slot="footer">
        <el-button type="text" @click="usingTemplate = false">取消</el-button>
        <el-button type="primary" :disabled="!specTemplates || !specTemplates.length" @click="handleSpecGenByTemplate">创建规格</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="custom.show" title="添加新规格" append-to-body :close-on-click-modal="false" width="560px" @closed="custom.form = null">
      <el-alert type="warning" show-icon title="系统提示" description="当前商品已经有订单记录，添加规格不会变更这些已销售、已创建的订单中该商品的规格信息，请知悉。" :closable="false" effect="dark" style="margin-bottom: 20px;" v-if="locked" />
      <el-form ref="customForm" :model="custom.form" :rules="custom.rules" label-position="right" label-suffix=":" label-width="84px" v-if="custom.form">
        <el-form-item prop="name" label="规格属性">
          <el-input v-model="custom.form.name" :maxlength="20" placeholder="请输入规格属性，如：尺寸、颜色等" />
        </el-form-item>
        <el-form-item prop="value" label="规格值">
          <el-input v-model="custom.form.value" placeholder="请输入规格的值，多个值使用“|”（不含引号）进行分隔" />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="text" @click="custom.show = false">取消</el-button>
        <el-button type="primary" @click="handleSpecGenByCustom">确定</el-button>
      </div>
    </el-dialog>

    <code-picker ref="codePicker" :is-import="pickMode === 'import'" @select="handleErpCodeChange" @import="handleSkuImport" v-if="$erp.enable()" />

    <el-dialog :visible.sync="editor.visible" title="SKU信息" :close-on-click-modal="false" custom-class="app-commodity-manage-skus-editor" append-to-body width="980px" @closed="editor.form = null">
      <el-form ref="adderForm" :model="editor.form" :rules="rules" label-position="top" v-if="editor.form">
        <div class="h wrap">
          <el-form-item v-for="s in specification" :key="s.prop" :prop="s.prop" :label="s.name" class="_form-item">
            <el-autocomplete v-model="editor.form[s.prop]" :fetch-suggestions="handleSpecSuggestions(s)" :debounce="0" clearable :placeholder="`请选择或输入${s.name}`" style="width: 100%" />
            <!-- <el-select v-model="editor.form[s.prop]" allow-create filterable clearable :placeholder="`请选择或输入${s.name}`" style="width: 100%;">
              <el-option v-for="sv in s.valueArray" :key="sv.value" :value="sv.value" :label="sv.value" />
            </el-select>-->
          </el-form-item>
        </div>
        <div class="h">
          <el-form-item label="SKU编码" class="_form-item">
            <el-input v-model="editor.form.code" :maxlength="40" />
          </el-form-item>
          <el-form-item label="ERP编码" class="_form-item">
            <div class="h">
              <el-input v-model="editor.form.erpCode" :readonly="editor.form._loading" :maxlength="40" class="flex" @change="handleERPInfo(editor.form)">
                <el-button slot="append" icon="fas fa-mouse-pointer" :loading="editor.form._loading" @click="handleErpCodePick(editor.form)"></el-button>
              </el-input>
            </div>
          </el-form-item>
          <el-form-item label="统一零售价" class="_form-item">
            <price-input v-model="editor.form.retailPrice" :min="0" :max="999999" :step-ratio="10000" />
          </el-form-item>
        </div>
        <div class="h">
          <el-form-item label="常用规格" class="_form-item small">
            <el-switch v-model="editor.form.routine" />
          </el-form-item>
          <el-form-item label="是否上架" class="_form-item small">
            <el-switch v-model="editor.form.onShelf" />
          </el-form-item>
          <el-form-item label="可使用返利">
            <el-switch v-model="editor.form.joinRebate" />
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" v-if="editor.form">
        <el-button type="text" @click="editor.visible = false">取消</el-button>
        <el-button type="primary" :disabled="editor.form._loading" @click="handleSkuAddSubmit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getByErpCode, getSkus, saveSkus, specRename } from "@/api/goods";
import eSkuDetail from "./sku-detail";
import codePicker from "./code-picker";
import checkPermission from "@/utils/permission";

const defaultSku = {
  id: null,
  barcode: null,
  code: null,
  coverImg: null,
  deliveryCycle: null,
  displayNo: null,
  enable: true,
  enableEdit: true,
  entId: null,
  erpCode: null,
  fileId: null,
  goods: null,
  isDeleted: false,
  isImport: false,
  isRebate: false,
  isSaleTarget: true,
  joinRebate: true,
  lowestRetailPrice: 0,
  minSalesQuantity: 1,
  name: "",
  onShelf: true,
  price: null,
  retailPrice: 0,
  routine: false,
  saveBatchNo: null,
  specTemplate: null,
  spuId: null,
  stock: null,
  specs: "",
  _checked: false,
  _loading: false,
  _sid: null,
  _specs: null,
};

export default {
  components: { eSkuDetail, codePicker },
  props: {
    form: Object,
    editable: Boolean,
  },
  data() {
    return {
      specTemplates: [],
      specTemplateArray: [],
      specDelimiter: "|",
      specification: [],
      specificationObject: {},
      pickMode: "import",
      locked: true,
      originSkus: [],
      skus: [],
      keywords: {},
      page: 1,
      size: 10,
      usingTemplate: false,
      current: null,
      custom: {
        show: false,
        form: null,
        rules: {
          name: [{ required: true, message: "请输入规格属性" }],
          value: [{ required: true, message: "请输入规格值" }],
        },
      },
      unify: {
        onShelf: true,
        // stock: 0,
        // minSalesQuantity: 1,
        // lowestRetailPrice: 0,
        // retailPrice: 0,
        // isRebate: true,
        joinRebate: true,
        routine: true,
      },
      formSkus: {},
      detail: {
        sku: null,
        show: false,
      },
      editor: {
        visible: false,
        form: null,
      },
      loading: false,
      saving: false,
    };
  },
  computed: {
    specEditable() {
      return this.checkPermission(["GOODS_SKU_SPEC_MODIFY"]);
    },
    pagedSkus() {
      let start = (this.page - 1) * this.size,
        len = this.size;
      if (start + len > this.skus.length) {
        len = this.skus.length - start;
      }
      return this.skus.slice(start, start + len);
    },
    columns() {
      let cols = [];
      if (this.specification && this.specification.length) {
        cols = this.specification.map((s, i) => {
          return {
            label: s.name,
            prop: s.prop,
            filters: s.valueArray.map((v) => {
              return {
                text: v.value,
                value: v.value,
              };
            }),
          };
        });
      } else {
        cols.push({
          label: "规格",
          prop: "_specs",
        });
      }
      return cols;
    },
    rules() {
      let rules = {};
      (this.columns || []).forEach((col) => {
        rules[col.prop] = [
          { required: true, message: `请选择或输入${col.label}` },
        ];
      });
      return rules;
    },
  },
  methods: {
    checkPermission,
    getSkuRowClass({row}) {
      return row.enableEdit !== true ? "el-table--wraning-row-1" : "";
    },
    doFilter() {
      let fn = [];
      for (let k in this.keywords) {
        if (this.keywords.hasOwnProperty(k)) {
          if (typeof this.keywords[k] === "string") {
            if (this.keywords[k] != "") {
              let kw = this.keywords[k].toLowerCase();
              fn.push(`d.${k}.toLowerCase().indexOf("${kw}") >= 0`);
            }
          } else if (this.keywords[k] != null) {
            fn.push(`d.${k} === ${this.keywords[k]}`);
          }
        }
      }
      if (fn && fn.length) {
        let f = fn.join(" && ");
        this.skus = this.originSkus.filter((d) => {
          return eval(f);
        });
      } else {
        this.skus = this.originSkus;
      }
    },
    doOnShelfChange(val) {
      if (this.keywords) {
        this.keywords.onShelf = val;
        this.doFilter();
      }
    },
    doLayout() {
      this.$refs.list && this.$refs.list.doLayout();
    },
    handleImport() {
      this.pickMode = "import";
      this.$refs.codePicker && this.$refs.codePicker.show();
    },
    loadFormSkus(callback = null) {
      if (this.form && this.form.id) {
        this.loading = true;
        getSkus(this.form.id)
          .then((res) => {
            this.analysisSpecs(res);
            this.form.skus = res;
            this.analysisSkusSid();
            if (typeof callback === "function") callback();
          })
          .then((_) => {
            this.loading = false;
          });
      }
    },
    handleErpCodePick(row) {
      this.pickMode = "setcode";
      this.current = row;
      this.$refs.codePicker && this.$refs.codePicker.show();
    },
    handleErpCodeChange(row) {
      if (row && this.current) {
        this.current.erpCode = row.erpCode;
        this.handleERPInfo(this.current);
      }
    },
    handleERPInfo(row) {
      if (row && row.erpCode) {
        row._loading = true;
        getByErpCode(row.erpCode)
          .then((res) => {
            if (res && res.skus && res.skus.length) {
              this.$confirm(
                `ERP编码【${row.erpCode}】对应的商品信息如下：<br />商品名称：${
                  res.name
                }<br />商品规格：${this.$goodsSpecConvert(
                  res.skus[0].specs
                )}<br />确定要使用该ERP编码吗？`,
                "ERP提示",
                {
                  type: "warning",
                  dangerouslyUseHTMLString: true,
                }
              )
                .then(() => {
                  row.code = row.erpCode;
                })
                .catch((_) => {
                  row.erpCode = "";
                })
                .finally((_) => {
                  row._loading = false;
                });
            } else {
              this.$confirm(
                `未能找到ERP编码【${row.erpCode}】对应的商品信息，确定要使用该ERP编码吗？`,
                "ERP提示",
                {
                  type: "warning",
                  dangerouslyUseHTMLString: true,
                }
              )
                .then()
                .catch((_) => {
                  row.erpCode = "";
                })
                .finally((_) => {
                  row._loading = false;
                });
            }
          })
          .catch((_) => {
            row.erpCode = "";
          })
          .finally((_) => {
            row._loading = false;
          });
      }
    },
    analysisSpecs(res, _arr, _obj, _updateSkus = false) {
      let arr = _arr || [],
        obj = _obj || {},
        ko = {
          erpCode: "",
          code: "",
          onShelf: null,
        },
        sinx = arr.length,
        locked = false;
      res.forEach((r) => {
        try {
          r._loading = false;
          r._checked = false;
          r._specs =
            typeof r.specs === "string" ? JSON.parse(r.specs) : r.specs;
          r._specs.forEach((d) => {
            if (d.name && d.value) {
              let _values = d.value
                .split(this.specDelimiter)
                .map((v) => v.replace(/^\s+|\s+$/gi, ""))
                .filter((v) => !!v);
              if (_values && _values.length) {
                if (!obj.hasOwnProperty(d.name)) {
                  let pk = "spec_" + sinx;
                  obj[d.name] = {
                    index: sinx,
                    name: d.name,
                    prop: pk,
                    _vinx: 0,
                    _newValue: "",
                    values: {},
                    valueArray: [],
                  };
                  ko[pk] = "";
                  arr.push(obj[d.name]);
                  sinx++;
                  if (
                    _updateSkus &&
                    this.originSkus &&
                    this.originSkus.length
                  ) {
                    this.originSkus.forEach((sku) => {
                      sku._specs.push({
                        name: d.name,
                        value: _values[0],
                      });
                      sku.specs = JSON.stringify(sku._specs);
                      this.$set(sku, pk, _values[0]);
                      this.$set(sku, "_sid", sku._sid + "_0");
                    });
                  }
                }
                let o = obj[d.name];
                d._values = _values;
                d._values.forEach((v) => {
                  if (!o.values.hasOwnProperty(v)) {
                    o.values[v] = {
                      index: o._vinx,
                      value: v,
                      locked: false,
                    };
                    o.valueArray.push(o.values[v]);
                    o._vinx++;
                  }
                  if (r.enableEdit === false) {
                    o.values[v].locked = true;
                    locked = true;
                  }
                });
              }
            }
          });
        } catch (e) {
          console.log(e);
        }
      });
      this.keywords = ko;
      this.locked = locked;
      this.specification = arr;
      this.specificationObject = obj;
    },
    handleSpecGenByTemplate() {
      if (this.specTemplateArray && this.specTemplateArray.length) {
        this.usingTemplate = false;
        this.analysisSpecs(this.specTemplateArray);
        // let arr = [];
        // this.generateSkus(0, "", [], {}, arr);
        this.originSkus = [];
        this.doFilter();
      }
    },
    handleSpecGenByCustom() {
      this.$refs.customForm &&
        this.$refs.customForm.validate().then((_) => {
          this.custom.show = false;
          this.analysisSpecs(
            [{ specs: [this.custom.form] }],
            this.specification,
            this.specificationObject,
            true
          );
          // let arr = [];
          // this.generateSkus(0, "", [], {}, arr);
          // this.originSkus = [];
          this.doFilter();
        });
    },

    handleSkuAdd() {
      if (
        this.form &&
        this.specification &&
        this.specification.length &&
        this.columns &&
        this.columns.length
      ) {
        let form = Object.assign({}, defaultSku, {
          fileId: this.$uuid(),
          spuId: this.form.id,
        });
        this.columns.forEach((col) => {
          form[col.prop] = null;
        });
        this.editor.form = form;
        this.editor.visible = true;
      }
    },

    handleSkuEdit(sku) {
      this.editor.form = JSON.parse(JSON.stringify(sku));
      this.editor.visible = true;
    },

    handleSkuAddSubmit() {
      this.$refs.adderForm &&
        this.$refs.adderForm.validate().then(() => {
          let fd = this.editor.form,
            _ids = [],
            specs = [];
          this.specification.forEach((s) => {
            let val = fd[s.prop];
            if (!s.values.hasOwnProperty(val)) {
              // 如果不存在规格值，则进行添加
              s.values[val] = {
                index: s._vinx,
                value: val,
                locked: false,
              };
              s.valueArray.push(s.values[val]);
              s._vinx++;
            }
            _ids.push(s.values[val].index);
            specs.push({ name: s.name, value: val });
          });

          let _sid = _ids.join("_");
          let exist = this.originSkus.find(
            (o) => o._sid === _sid && (!fd.id || o.id !== fd.id)
          );
          if (exist) {
            this.$alert("已经存在当前规格的SKU。", "系统提示", {
              type: "warning",
            });
          } else {
            fd._sid = _sid;
            fd._specs = specs;
            fd.specs = JSON.stringify(specs);
            if (fd.id) {
              // 如果是编辑
              let origin = this.originSkus.find((o) => o.id === fd.id);
              Object.assign(origin, fd);
            } else {
              this.originSkus.push(fd);
            }
            this.doFilter();
            this.editor.visible = false;
          }
        });
    },

    handleSkuRemove(sku) {
      let inx = this.originSkus.findIndex((o) => o._sid === sku._sid);
      if (inx > -1) this.originSkus.splice(inx, 1);
    },

    // handleSpecValueAdd(s) {
    //   if (s && s._newValue) {
    //     let exist = s.valueArray.find((v) => {
    //       return v.value === s._newValue;
    //     });
    //     if (exist) {
    //       this.$message.error(`该${s.name}已经存在`);
    //     } else {
    //       s.values[s._newValue] = {
    //         index: s._vinx,
    //         value: s._newValue,
    //         locked: false,
    //       };
    //       s.valueArray.push(s.values[s._newValue]);
    //       s._vinx++;
    //       let arr = [];
    //       this.generateSkus(0, "", [], {}, arr);
    //       this.originSkus = arr;
    //       this.doFilter();
    //       let el = this.$refs[`spec_adder_${s.index}`];
    //       el && el[0].doClose();
    //     }
    //   }
    // },
    // handleSpecValueRemove(s, v) {
    //   if (s && s.values && v && v.value && s.values.hasOwnProperty(v.value)) {
    //     let inx = s.valueArray.indexOf(v);
    //     if (inx >= 0) s.valueArray.splice(inx, 1);
    //     delete s.values[v.value];
    //     if (!s.valueArray.length) {
    //       inx = this.specification.indexOf(s);
    //       if (inx >= 0) this.specification.splice(inx, 1);
    //       delete this.specificationObject[s.name];
    //     }
    //     let arr = [];
    //     this.generateSkus(0, "", [], {}, arr);
    //     this.originSkus = arr;
    //     this.doFilter();
    //   }
    // },
    handleSpecSuggestions(specItem) {
      return (kw, cb) => {
        if (kw) {
          cb(
            (specItem.valueArray || []).filter((v) => {
              return v.value.indexOf(kw) >= 0;
            })
          );
        } else {
          cb(specItem.valueArray || []);
        }
      };
    },
    handleSpecBeforeChange(oldVal, newVal) {
      return new Promise((resolve, reject) => {
        this.$confirm(
          "更改规格模板并保存后，旧的SKU信息可能将被清除！<br />您确定要更改规格模板吗？",
          "操作确认",
          {
            type: "warning",
            dangerouslyUseHTMLString: true,
          }
        )
          .then(resolve)
          .catch(reject);
      });
    },
    handleSpecTemplateChange(val, oldval, templates) {
      this.specTemplateArray = templates;
    },
    handleSpecRename(specItem, oldValue) {
      this.handleSubmit(() => {
        this.$prompt("请输入新的规格值：", "修改规格值", {
          inputValue: oldValue,
        })
          .then((e) => {
            let err = null;
            let val = (e.value || "").replace(/^\s+|\s+$/gi, "");
            if (val) {
              if (specItem.values.hasOwnProperty(val)) {
                err = "该规格值已存在";
              }
            } else {
              err = "请输入规格值";
            }
            if (err) {
              this.$alert(err, "系统提示", { type: "warning" });
            } else {
              let loader = this.$loading({
                lock: true,
                text: "正在重命名",
              });
              specRename({
                spuId: this.form.id,
                name: specItem.name,
                oldValue,
                newValue: val,
              })
                .then(() => {
                  this.$message.success("重命名成功");
                  this.loadFormSkus();
                })
                .catch((err) => {
                  this.$message.error("重命名失败");
                })
                .finally(() => {
                  loader.close();
                });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    handleSelection(val = null) {
      if (this.skus && this.skus.length) {
        this.skus.forEach((s) => {
          s._checked = val === null ? !s._checked : val;
        });
      }
    },
    // handleAutoSelection() {
    //   if (this.skus && this.skus.length) {
    //     this.skus.forEach((s) => {
    //       s.enable = !!s.erpCode;
    //     });
    //   }
    // },
    handleUnifyCommand(cmd) {
      if (this.skus && this.skus.length) {
        this.skus.forEach((s) => {
          if (cmd === 0 || s._checked === cmd) {
            Object.assign(s, this.unify);
          }
        });
      }
    },
    generateSkus(inx, prefix, specs, props, arr) {
      if (this.specification && this.specification.length) {
        if (inx >= this.specification.length - 1) {
          (this.specification[inx].valueArray || []).forEach((v) => {
            let prop = {};
            prop["spec_" + inx] = v.value;
            if (this.formSkus.hasOwnProperty(prefix + v.index)) {
              arr.push(
                Object.assign({}, this.formSkus[prefix + v.index], props, prop)
              );
            }
          });
        } else {
          (this.specification[inx].valueArray || []).forEach((v) => {
            let prop = {};
            prop["spec_" + inx] = v.value;
            this.generateSkus(
              inx + 1,
              prefix + v.index + "_",
              []
                .concat(specs)
                .concat([
                  { name: this.specification[inx].name, value: v.value },
                ]),
              Object.assign({}, props, prop),
              arr
            );
          });
        }
      }
    },
    analysisSkusSid() {
      if (this.form) {
        if (this.form.skus && this.form.skus.length) {
          let formSkus = {};
          for (let s of this.form.skus) {
            try {
              s._specs = JSON.parse(s.specs);
              s._sid = null;
              if (s._specs && s._specs.length) {
                let ids = [],
                  wrong = false;
                for (let i = 0, l = s._specs.length; i < l; i++) {
                  let so = s._specs[i];
                  let sd = this.specificationObject[so.name];
                  so.value = so.value.replace(/^\s+|\s+$/gi, "");
                  if (sd && sd.values && sd.values.hasOwnProperty(so.value)) {
                    ids[sd.index] = sd.values[so.value].index;
                  } else {
                    wrong = true;
                    break;
                  }
                }
                if (!wrong) {
                  s._sid = ids.join("_");
                  formSkus[s._sid] = s;
                }
              }
            } catch (e) {}
          }
          this.formSkus = formSkus;
        }
        let arr = [];
        this.generateSkus(0, "", [], {}, arr);
        this.originSkus = arr;
        this.doFilter();
      }
    },
    handleSubmit(callback = null) {
      this.saving = true;
      saveSkus(this.form.id, {
        skus: this.originSkus,
        specTemplate: this.form.specTemplate,
      })
        .then((res) => {
          this.$notify({
            title: "保存SKU信息成功",
            type: "success",
            duration: 2500,
          });
          this.loadFormSkus(callback);
        })
        .finally((_) => {
          this.saving = false;
        });
    },
    handleSkuDetail(row) {
      this.detail.sku = row;
      this.detail.show = true;
    },
    handleSkuImport(items) {
      if (!this.specification || this.specification.length <= 1) {
        let replaceName = null;
        if (this.specification.length) {
          replaceName = this.specification[0].name;
        }

        items.forEach((o) => {
          o.isImport = true;
          if (replaceName) {
            let specs = JSON.parse(o.specs);
            if (specs && specs.length) {
              specs.forEach((sp) => {
                sp.name = replaceName;
              });
              o.specs = JSON.stringify(specs);
            }
          }
        });

        this.analysisSpecs(items, this.specification, this.specificationObject);
        this.form.skus = (this.form.skus || []).concat(items);
        this.analysisSkusSid();
      }
    },
  },
  mounted() {
    this.loadFormSkus();
  },
};
</script>

<style lang="less">
.app-commodity-manage-skus {
  ._spec-group {
    margin-left: 20px;
    label {
      margin-right: 5px;
      color: #606266;
    }
  }

  &-editor {
    ._form-item {
      width: 220px;
      margin-right: 20px;
      &:nth-child(4n) {
        margin-right: 0;
      }

      &.small {
        width: 100px;
      }
    }
  }
}
</style>